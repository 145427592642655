<template>
  <div
    v-if="showAuthenticatedLayout"
    class="app tracking-[.02em] text-zinc-800"
  >
    <div v-show="auth0?.isAuthenticated">
      <StoreInitializer />
      <HeaderNavBar />
      <SidebarSideBar />
      <div class="content__wrapper">
        <div class="w-full">
          <div class="top-nav-buffer" />
          <div>
            <div class="flex justify-center">
              <div class="content-container content-container-authenticated">
                <slot />
              </div>
            </div>
            <FooterDefaultFooter class="ml-24" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="app tracking-[.02em] text-zinc-800">
    <div class="content__wrapper">
      <div class="w-full">
        <div>
          <div class="flex justify-center">
            <div class="content-container content-container-unauthenticated">
              <slot />
            </div>
          </div>
          <FooterDefaultFooter class="ml-24" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";
import { useAuth0 } from "@auth0/auth0-vue";
import { useRoute } from "#vue-router";
import { IsUnauthenticatedRoute } from "~/utils/IsUnauthenticatedRoute";

const auth0 = useAuth0();
const route = useRoute();

onBeforeMount(() => {
  if (process.client) {
    const { locale } = useI18n();
    let userLanguage = localStorage.getItem("language");
    if (userLanguage === "null") userLanguage = null;

    locale.value = userLanguage ?? "en-us";
  }
});

const showAuthenticatedLayout = computed(() => {
  return process.server
    ? !IsUnauthenticatedRoute(route?.path ?? "")
    : auth0?.isAuthenticated?.value &&
        !IsUnauthenticatedRoute(route?.path ?? "");
});
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap");

.app {
  @apply bg-zinc-100 min-h-screen;
  font-family: Lato, Helvetica, sans-serif;
}
.content__wrapper {
  @apply flex;
}
.top-nav-buffer {
  @apply h-20;
}
/* .content-container {
  @apply w-full flex-1 overflow-hidden 2xl:max-w-[110rem] bg-white-100 rounded-lg ml-28 mr-4 my-3;
} */
.content-container {
  @apply w-full flex-1 overflow-hidden 2xl:max-w-[110rem] bg-white-100 rounded-lg;
}
.content-container-authenticated {
  @apply ml-28 mr-4 my-3;
}
.content-container-unauthenticated {
  @apply mt-10;
}
</style>
