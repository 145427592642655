<template>
  <div class="sidebar-option__wrapper" :class="{ isActive: selected }">
    <div class="sidebar-icon">
      <div
        v-if="isImpersonated && name === 'Settings'"
        class="flex flex-col justify-center items-center gap-[7px]"
      >
        <el-tooltip
          :content="toolTipContent"
          :raw-content="true"
          placement="right"
          popper-class="applied-filters-tooltip"
        >
          <div
            class="h-7 w-7 rounded-full text-center !bg-orange-100 flex justify-center items-center"
          >
            <span class="text-white-100 font-normal">{{
              userFirstInitial
            }}</span>
          </div>
        </el-tooltip>
        <span class="text-xs font-normal"> {{ $t(displayText) }}</span>
      </div>
      <div v-else class="flex flex-col items-center justify-center gap-[7px]">
        <ClientOnly>
          <font-awesome-icon class="text-2xl" :icon="icon" />
        </ClientOnly>
        <span class="text-xs font-normal"> {{ $t(displayText) }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useUserStore } from "@/store/user";
import { useOrgStore } from "@/store/org";
import { replaceAsterisks } from "~/utils/replaceAsterisks";

const userStore = useUserStore();
const user = process.client ? userStore.getUser : null;
const orgStore = useOrgStore();
const org = process.client ? orgStore.getOrg : null;

const { t } = useI18n();

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  displayText: {
    type: String,
    required: true,
  },
  icon: {
    type: [String, Array<String>],
    required: true,
  },
  selectedOption: {
    type: String,
    required: false,
    default: "",
  },
});

const selected = computed(() => {
  return props.selectedOption === props.name;
});

const isImpersonated = computed(() => {
  return user?.is_impersonated;
});

const userFirstInitial = computed(() => {
  return user?.name[0];
});

const toolTipContent = computed(() => {
  const replacements = [
    "<span class='text-green-150'>",
    "</span><br>",
    "<span class='text-green-150'>",
    "</span>",
  ];

  const translation = t("sidebar.impersonating", {
    user: user?.name,
    org: org?.attributes.name,
  });

  return replaceAsterisks(translation, replacements);
});
</script>

<style scoped>
.sidebar-option__wrapper {
  @apply flex flex-col w-full text-center cursor-pointer !py-0;
}
.sidebar-icon {
  @apply flex justify-center items-center my-3 min-h-[28px];
}
.isActive {
  @apply bg-green-100 text-green-500;
}
</style>
