<template>
  <div class="relative">
    <div
      class="buttonPaddings buttonWidth text-center cursor-pointer flex flex-col justify-center relative afterClass"
      :class="{
        buttonMaxWidthFull: activeGroup === 'Monitor',
        buttonMaxWidthPartial: hasEmptySpace,
      }"
      @click="onClick"
    >
      {{ $t(displayText) }}
    </div>
    <div :class="{ isActive: selectedLink }" />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    displayText: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    selectedLink: {
      type: Boolean,
      default: false,
    },
    activeGroup: {
      type: String,
      default: "Monitor",
    },
  },
  computed: {
    hasEmptySpace() {
      return !(this.activeGroup === "Monitor");
    },
  },
  methods: {
    async onClick() {
      // using navigateTo instead of <NuxtLink> for full button instead of text link
      /*
        This sucks, but treating /manage subroutes as external as a stop-gap for a nuxt bug.
        See this thread: https://github.com/nuxt/nuxt/issues/13309
      */
      const isExternal =
        (this.path?.includes("pro/#") ||
          this.path?.includes("reports#/") ||
          this.path?.includes("/manage/articles")) ??
        false;
      await navigateTo(this.path, {
        external: isExternal,
      });
    },
  },
};
</script>

<style scoped>
.isActive {
  @apply absolute w-full bottom-0.5 text-green-400 border-b-2 border-solid border-green-400 mt-[-2px];
}

.buttonWidth {
  @apply lg:max-w-none lg:min-w-30 xl:min-w-40 2xl:min-w-50;
}

.buttonMaxWidthFull {
  /* when navbar has lack of space */
  @apply sm:max-w-[6rem] md:max-w-[8rem] lg:max-w-none;
}

.buttonMaxWidthPartial {
  /* when navbar has extra space to fill */
  @apply sm:max-w-[12rem] lg:max-w-none;
}

.buttonPaddings {
  @apply sm:pb-4 sm:px-3 pt-4 h-20 lg:px-4;
}
</style>
