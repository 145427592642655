<template>
  <footer>
    <div class="footer">{{ year }} {{ $t("footer.footerText") }}</div>
  </footer>
</template>

<script>
export default {
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>
<style scoped>
.footer {
  @apply text-center my-10 w-11/12 mx-auto pl-3;
}
</style>
